import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  Modal,
  Button,
  CssBaseline,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import { injectIntl, navigate } from "gatsby-plugin-intl"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))
const VolverLogin = ({ intl }) => {
  const { configuracion } = useStaticQuery(graphql`
    query {
      configuracion: strapiConfiguracion {
        volver_login {
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          titulo {
            es
            en
            pt
          }
          boton {
            es
            en
            pt
            color_fondo
            color_letra
          }
        }
      }
    }
  `)
  const classes = useStyles()
  const currentLocale = intl.locale
  const body = (
    <div className={classes.paper} style={{textAlign: 'center'}}>
      <CssBaseline />

      <Typography component="h6" variant="h6" style={{ textAlign: "center" }}>
        {currentLocale === "en"
          ? configuracion.volver_login.titulo.en
          : currentLocale === "pt"
          ? configuracion.volver_login.titulo.pt
          : configuracion.volver_login.titulo.es}
      </Typography>
      <br />
      {configuracion.volver_login.imagen !== null &&
      configuracion.volver_login.imagen.localFile !== null ? (
        <div style={{ textAlign: "center" }}>
          <GatsbyImage
            image={
              configuracion.volver_login.imagen.localFile.childImageSharp
                .gatsbyImageData
            }
            alt="imagen"
            //     style={{ height: altura }}
          />
        </div>
      ) : (
        ""
      )}
      <br />

      <Button
        // fullWidth
        style={{
          background: `${configuracion.volver_login.boton.color_fondo}`,
          color: `${configuracion.volver_login.boton.color_letra}`,
        }}
        onClick={() => {
          navigate("/")
        }}
      >
        {currentLocale === "en"
          ? configuracion.volver_login.boton.en
          : currentLocale === "pt"
          ? configuracion.volver_login.boton.pt
          : configuracion.volver_login.boton.es}
      </Button>
    </div>
  )
  return (
    <Modal
      open={true}
      //  onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  )
}

export default injectIntl(VolverLogin)
