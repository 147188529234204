import React, { useContext } from "react"
import { graphql } from "gatsby"
import { AuthContext } from "../context/auth"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import "../css/background-image.css"
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { injectIntl } from "gatsby-plugin-intl"
import Botones from "../components/Botones"
import Carteles from "../components/Carteles"
import CartelesMovil from "../components/CartelesMovil"
import Videos from "../components/Videos"
import { Grid, Hidden } from "@material-ui/core"
import ChatNetworking from "../components/ChatNetworking"
import VolverLogin from "../components/VolverLogin"
import BotonesMovil from "../components/BotonesMovil"

const Networking = ({ data, intl }) => {
  const { user } = useContext(AuthContext)
  const { nombre, imagen_fondo, networking, carteles, titulo_chat_networking } =
    data.strapiNetworking
  const currentLocale = intl.locale
  const image = getImage(imagen_fondo.localFile)
  const bgImage = convertToBgImage(image)

  return (
    <>
      {user != null ? (
        <Layout>
          <SEO
            title={
              currentLocale === "en"
                ? nombre.en
                : currentLocale === "pt"
                ? nombre.pt
                : nombre.es
            }
          />
          <Hidden xsDown>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
              style={{ position: "fixed", top: 0 }}
              className="backimage"
            >
              {carteles.map(cartel => (
                <div
                  key={cartel.id}
                  style={{
                    position: "absolute",
                    left: cartel.left,
                    top: cartel.top,
                    width: cartel.width,
                  }}
                >
                  <Carteles cartel={cartel} />
                </div>
              ))}
              <div
                style={{
                  position: "absolute",
                  right: "0%",
                  top: "0%",
                  backgroundColor: "#FFFFFF99",
                  height: "90%",
                  width: "20%",
                }}
              >
                {/* MISMO TAMAÑO width QUE LE PASO AL TOTAL DEL CHAT en css */}
                {/*                 <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setChat(false)}
                >
                  CERRAR CHAT X
                </Button> */}
                <div style={{ textAlign: "center" }}>
                  {currentLocale === "en"
                    ? titulo_chat_networking.en
                    : currentLocale === "pt"
                    ? titulo_chat_networking.pt
                    : titulo_chat_networking.es}
                </div>
                <ChatNetworking auditorio={"networking"} />
              </div>

              {networking.map(items => (
                <div key={items.id}>
                  {items.strapi_component === "botones.boton" ? (
                    <div
                      style={{
                        position: "absolute",
                        left: items.left,
                        top: items.top,
                        width: items.width,
                      }}
                    >
                      <Botones boton={items} />
                    </div>
                  ) : items.strapi_component === "videos.video" ? (
                    <div
                      style={{
                        position: "absolute",
                        left: items.left,
                        top: items.top,
                        width: items.width,
                        height: items.height,
                      }}
                    >
                      <Videos video={items} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </BackgroundImage>
          </Hidden>

          {/*
INICIO DE VERSION PARA CELULAR
*/}
          <Hidden smUp>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                backgroundColor: `${data.configuracion.color_movil}`,
              }}
            >
              {data.configuracion.logo_movil !== null &&
              data.configuracion.logo_movil.localFile !== null ? (
                <GatsbyImage
                  image={
                    data.configuracion.logo_movil.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt="cabecera"
                />
              ) : (
                ""
              )}
              <div>&nbsp;</div>
              {imagen_fondo !== null && imagen_fondo.localFile !== null ? (
                <GatsbyImage
                  image={imagen_fondo.localFile.childImageSharp.gatsbyImageData}
                  alt="ambiente"
                />
              ) : (
                ""
              )}
              <div>&nbsp;</div>

              <div>&nbsp;</div>
              {networking.map(items => (
                <div key={items.id}>
                  {items.strapi_component === "botones.boton" ? (
                    <Grid item xs={12} style={{ marginTop: 20, width: "100%" }}>
                      <BotonesMovil boton={items} />
                    </Grid>
                  ) : items.strapi_component === "videos.video" ? (
                    <Grid item xs={12}>
                      <Videos video={items} />
                    </Grid>
                  ) : (
                    ""
                  )}
                </div>
              ))}
              <div>&nbsp;</div>
              {carteles.map(cartel => (
                <Grid item xs={12} key={cartel.id}>
                  <CartelesMovil cartel={cartel} />
                </Grid>
              ))}
            </Grid>
          </Hidden>
        </Layout>
      ) : (
        <VolverLogin />
      )}
    </>
  )
}

export default injectIntl(Networking)

export const query = graphql`
  query {
    configuracion: strapiConfiguracion {
      color_movil
      logo_movil {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    strapiNetworking {
      titulo_chat_networking {
        es
        en
        pt
      }
      nombre {
        es
        en
        pt
      }
      imagen_fondo {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      carteles {
        ampliar_cartel
        mostrar_celular
        mostrar
        nombre
        proporcion
        left
        id
        tiempo_slideshow
        top
        width
        marco_size
        color_fondo_marco
        slideshow_imagenes {
          id
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  width: 1000
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          imagen_link
          imagen_link_tipo
        }
      }
      networking
    }
  }
`
